import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormModalLayout from 'components/Molecules/DemandeRf/FormModalLayout/FormModalLayout';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import Loader from 'components/Atoms/Loader/Loader';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';
import { FAMILY_TYPE } from 'constants/regroupementFamiliale';
import { Conjoint, Enfant } from 'models/types/api/rf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { useUpdConjointMutation, useUpdEnfantMutation } from 'redux/rtk/api/apiRf';
import { updAddressesStore, updConjointStore, updEnfantStore } from 'redux/demandeRf';
import { getListAddress } from 'redux/demandeRf/selectors';
import { useGetUsagerListePaysQuery } from 'redux/rtk/api/apiCommon';
import { createError } from 'redux/forms';

import AddressUseForm from './components/AddressUseForm';
import AddressChoice from './components/AddressChoice';
import addressMapping from './helpers/addressMapping';
import { addressAppToApiFormatter } from './helpers/addressAppToApiFormatter';

interface AddressProps<T> {
  beneficiaire: T;
  demandeId: string;
  addressModal: any;
  type: 'conjoint' | 'enfant';
  lang: string;
}

const Address: React.FC<AddressProps<Conjoint | Enfant>> = ({
  beneficiaire,
  demandeId,
  addressModal,
  type,
  lang,
}) => {
  const [forceModalOpen, setForceModalOpen] = useState<boolean>(false);
  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();
  const [
    updConjoint,
    { data: dataConjoint, isSuccess: isSuccessConjoint, isError: isErrorConjoint },
  ] = useUpdConjointMutation();
  const [updEnfant, { data: dataEnfant, isSuccess: isSuccessEnfant, isError: isErrorEnfant }] =
    useUpdEnfantMutation();
  const dispatch = useDispatch();
  const addresses = useSelector(getListAddress);
  const { errorCms, bloc2Cms, globalCms, utilitaireCms } = getRfCmsContents(lang);
  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    register,
    formState, //isValid, errors, touchedFields
    control,
  } = AddressUseForm(errorCms, utilitaireCms, bloc2Cms);

  const formFieldsMapping = addressMapping.getGeneralMapping().getFormFields(
    { bloc2Cms, globalCms },
    {
      setValue,
      getValues,
      control,
      trigger,
    },
    lang,
  );

  const onSubmit = async (data: any, forceModal?: boolean) => {
    const beneficiaireId = beneficiaire?.id ? beneficiaire.id : null;
    const beneficiaireApi = addressAppToApiFormatter(data, beneficiaireId);
    if (forceModal !== undefined) {
      setForceModalOpen(forceModal);
    } else {
      setForceModalOpen(false);
    }
    if (type === FAMILY_TYPE.CONJOINT) {
      await updConjoint({
        demandeId: demandeId,
        body: beneficiaireApi,
      });
    } else {
      await updEnfant({
        demandeId: demandeId,
        body: beneficiaireApi,
      });
    }
  };

  useEffect(() => {
    if (isSuccessConjoint && dataConjoint) {
      dispatch(updConjointStore(dataConjoint));
      if (dataConjoint.adresse) {
        dispatch(updAddressesStore(dataConjoint.adresse));
      }
      if (!forceModalOpen) {
        addressModal.close();
      }
    }
  }, [isSuccessConjoint, dataConjoint]);

  useEffect(() => {
    if (isSuccessEnfant && dataEnfant) {
      dispatch(updEnfantStore(dataEnfant));
      if (dataEnfant.adresse) {
        dispatch(updAddressesStore(dataEnfant.adresse));
      }
      if (!forceModalOpen) {
        addressModal.close();
      }
    }
  }, [isSuccessEnfant, dataEnfant]);

  useEffect(() => {
    if (isErrorEnfant || isErrorConjoint) {
      dispatch(createError(errorCms.erreur_enregistrement));
    }
  }, [isErrorEnfant, isErrorConjoint]);

  useEffect(() => {
    reset({
      choix_adresse: beneficiaire?.adresse?.id ? beneficiaire?.adresse?.id : 'autre_adresse',
      numero_de_voie: beneficiaire?.adresse?.adresse.numero
        ? beneficiaire.adresse.adresse.numero
        : '',
      nom_de_rue: beneficiaire?.adresse?.adresse?.adresse1
        ? beneficiaire?.adresse?.adresse.adresse1
        : '',
      code_postal: beneficiaire?.adresse?.adresse?.codePostal
        ? beneficiaire?.adresse?.adresse.codePostal
        : '',
      localite: beneficiaire?.adresse?.adresse?.ville ? beneficiaire?.adresse?.adresse.ville : '',
      province: beneficiaire?.adresse?.adresse?.province
        ? beneficiaire?.adresse?.adresse.province
        : '',
      pays: beneficiaire?.adresse?.adresse?.pays ? beneficiaire?.adresse?.adresse.pays : '',
      complement_adresse: beneficiaire?.adresse?.adresse?.adresse2
        ? beneficiaire?.adresse?.adresse.adresse2
        : '',
      adresse_email: beneficiaire?.adresse?.adresse?.email
        ? beneficiaire?.adresse?.adresse.email
        : '',
      telephone_adresse: beneficiaire?.adresse?.adresse?.telephone
        ? beneficiaire?.adresse?.adresse.telephone
        : '',
    });
  }, [beneficiaire.adresse]);

  if (errorPays) {
    return <div>Redirect page error</div>;
  }

  if (loadingPays) {
    return <Loader />;
  }
  return (
    <FormModalLayout
      formIdentifier="address-form"
      requiredInfos={true}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      lang={lang}
      control={control}
      isValid={formState.isValid}
      isNoValidationSubmit={false}
    >
      <AddressChoice
        cms={bloc2Cms}
        addresses={addresses}
        reset={reset}
        initVal={beneficiaire?.adresse?.id !== null ? beneficiaire?.adresse?.id : undefined}
      />
      <input {...register('choix_adresse')} type="hidden" />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.numero_de_voie}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.nom_de_rue}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.complement_adresse}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.code_postal}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.localite}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.province}
      />
      <AutocompleteContainer
        {...formFieldsMapping.pays}
        payload={listePays}
        source="API"
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.adresse_email}
      />
      <TextInputContainer
        customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
        {...formFieldsMapping.telephone_adresse}
      />
    </FormModalLayout>
  );
};

export default Address;
